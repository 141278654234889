// @ts-nocheck

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import {
  isUserLoggedIn,
  setStoredSessionData,
  getAuthTokenFromLocalStorage,
  parseJwtToken,
} from "../../lib/auth";
import { GOOGLE_LOGIN_CLIENT_ID } from "../../constants";
import { getAuthToken } from "../../lib/models/Authorisation";
import "./style.scss";
import { type IAppProps } from "../../App";
import LoginPageImage from "../../assets/icons/dark/Login";
import { LoginPage, useWindowSize } from "@zopsmart/zs-components";
import LoginMobileImage from "../../assets/icons/dark/LoginMobileImage";

function Login(props: IAppProps): React.ReactElement {
  const windowSize = useWindowSize();
  const isMobileScreen = windowSize.width <= 960;
  let authRes;
  const navigate = useNavigate();
  function parseJwt(token): any {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
  const onSuccess = async (googleusercontent): void => {
    const user = parseJwt(googleusercontent.credential);
    const userData = {
      name: user.name,
      firstName: user.given_name,
      lastName: user.family_name,
      email: user.email,
      profileImage: user.picture,
      idToken: googleusercontent.credential,
      refreshToken: "",
    };
    setStoredSessionData({ userData });
    try {
      authRes = await getAuthToken();
    } catch (err) {
      alert("Wrong Credential");
      navigate("/logout");
      if (faro?.api?.pushError) {
        faro.api.pushError(err);
      }
      return;
    }

    userData.id = authRes.id;
    userData.authToken = authRes.accessToken;
    userData.refreshToken = authRes.refreshToken;

    setStoredSessionData({
      userData,
    });
    props.appHooks.requestSessionValidation();
    navigate(getAfterLoginURL());
  };
  const addLoginButton = (): void => {
    try {
      if (google?.accounts !== undefined) {
        google.accounts.id.initialize({
          client_id: GOOGLE_LOGIN_CLIENT_ID,
          callback: onSuccess,
          ux_mode: "popup",
          // hosted_domain: "zopsmart.com",
        });
        google.accounts.id.renderButton(document.getElementById("my-signIn"), {
          theme: "outline",
          size: "large",
          type: "standard",
          width: "350px",
          text: "Signin with Google",
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw "new exception";
      }
    } catch (err) {
      setTimeout(addLoginButton, 1500);
      if (faro?.api?.pushError) {
        faro.api.pushError(err);
      }
    }
  };
  useEffect(() => {
    if (isUserLoggedIn()) {
      const myURL = localStorage.getItem("intendedURL");
      localStorage.removeItem("intendedURL");
      if (myURL !== null) {
        navigate(myURL);
      } else navigate(getAfterLoginURL());
    }
    addLoginButton();
  });

  const handleLoginComponent = (): React.ReactElement => {
    return (
      <div className="login-page-google-button">
        <div id="my-signIn" />
      </div>
    );
  };

  const handleBackgroundImage = (): React.ReactElement => {
    return <>{isMobileScreen ? <LoginMobileImage /> : <LoginPageImage />}</>;
  };

  return (
    <>
      <Helmet>
        <title>Log In | SkillUp</title>
        <meta
          name="description"
          content="Login to get yourself industry ready.."
        />
      </Helmet>
      <LoginPage
        imageAlignment="left"
        backgroundImage={handleBackgroundImage()}
        renderComponent={handleLoginComponent()}
        backgroundImageMobile={handleBackgroundImage()}
        title="Welcome to SkillUp"
      />
    </>
  );
}

export function getAfterLoginURL(): string {
  // if organization ID is present in the jwt then redirect to dashboard else to organization page
  const token = getAuthTokenFromLocalStorage();
  return parseJwtToken(token).organizationId === undefined
    ? "/orgs/join"
    : "/dashboard";
}

export default Login;
